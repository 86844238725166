<template>
    <div class="home">
      <div class="pt20"></div>
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
<el-form-item label="生成时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="标签分类">
            <el-select style="width:150px;margin-left:20px;" v-model="status" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
            </el-select>
            <el-select style="margin-left:20px;" v-if="options2.length != 0" v-model="status2" placeholder="请选择">
                  <el-option
                    v-for="item in options2"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
            </el-select>
            </el-form-item>

            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.query" style="width:300px" placeholder="操作人、标签编号、标签名称"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary"  style="margin-left:10px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
<div class="pt20"></div>
      <div class="left">
        <el-button v-if="add == 1" @click="goNav('/label/articleadd')" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(3)" type="danger" style="font-size: 16px;font-weight: bold;" plain>删除</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
          <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column
          prop="id"
          label="标签编号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="标签名">
        </el-table-column>
        <el-table-column
          prop="f_type.name" 
          label="一级分类">
        </el-table-column>
        <el-table-column
          prop="c_type.name" 
          label="二级分类">
        </el-table-column>
        <el-table-column
          prop="num" 
          label="使用量">
        </el-table-column>
        <el-table-column
        width="210"
          prop="created_at" 
          label="生成时间">
        </el-table-column>
        <el-table-column
          prop="operator_name" 
          label="操作人">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
        <el-button v-if="detail == 1" @click="goedit2(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      options:[],
      options2:[],
      formInline: {
         sdate:'',
         edate:'',
         query:'',
         page:1,
         f_type:-1,
         c_type:-1,
      },
      status:'',
      status2:-1,
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
      nums:[],
     //选中的记录数量
     initdata:{
         sdate:'',
         edate:'',
         query:'',
         page:1,
         f_type:-1,
         c_type:-1,
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  watch:{
    status:function(val){
      let _this = this;
      if(val != -1){
      axios.get(config.selectlabelCategory,{params:{id:val}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
              _this.status2 = -1;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      }else{
        _this.options2 = [];
        _this.status2 = -1;
      }
    },
  },
  methods: {
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.sdate = '';
          _this.formInline.edate = '';
          if(_this.timevalue){
            _this.formInline.sdate	 = formatDate(_this.timevalue[0]);
            _this.formInline.edate = formatDate(_this.timevalue[1]);
          }
          _this.formInline.f_type = _this.status?_this.status:-1;
          _this.formInline.c_type = _this.status2;
        axios.get(config.label,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/label/articleedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/label/articleedit');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/label/articleedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/label/articleedit');
      },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        for(let i in _this.nums){
          if(_this.nums[i] > 0){
            _this.$message.error('标签正在被使用，无法删除！');
            return;
          }
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.labelStatus, {id:this.ghs,status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.label,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        
        axios.get(config.selectlabelCategory,{params:{id:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
      
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 170px;
    text-align: left;
}
</style>